import React, { Component } from 'react';
import firebase from 'firebase';
import MaterialTable from "material-table";
import _ from 'lodash';
import { Link } from 'react-router-dom';

// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';

// Flashcard Icons
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';




export default class Phrase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            cardData: {},
            isAddNew: false,
            uid: '',
            dialogName: '',
            dialogId: '',
            topicName: '',
            topicId: '',
            rowId: -1,
        }
    }



    componentDidMount() {

        // Get dialogId from url the get data from firebase
        var dialogId = this.props.match.params.id;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.getCardsData(user.uid, dialogId);
            }
        });
    }

    getCardsData = (uid, dialogId) => {
        // Connect to firebase and convert firebase data to display on the table
        if (uid !== '') {
            let ref = firebase.database().ref(`users/${uid}/dialogs/${dialogId}`);
            ref.on('value', snapshot => {
                if (snapshot.val() !== undefined && snapshot.val() !== null) {
                    //console.log('snapshot.val().l1', snapshot.val().l1, snapshot.val().l2, snapshot.val().n);

                    // Convert data from 3 list into one list with each item has 3 properties
                    let cardsData = [];
                    if (snapshot.val().l1 !== undefined && snapshot.val().l1 !== null) {
                        let l1 = snapshot.val().l1;
                        let l2 = snapshot.val().l2;
                        let n = snapshot.val().n;
                        for (var i = 0; i < l1.length; i++) {
                            var rowData = { l1: l1[i], l2: l2[i], n: n[i] }
                            cardsData.push(rowData)
                        }
                    }
                    else {
                        cardsData = [];
                    }
                    console.log('DATA RETRIEVED', cardsData);

                    // Get other data
                    var dialogName = snapshot.val().name;
                    var topicId = snapshot.val().topicId;
                    this.getTopicName(uid, topicId);


                    this.setState({ data: cardsData, dialogId: dialogId, dialogName: dialogName, topicId: topicId, uid: uid });
                }
                else {
                    this.setState({ data: [] });
                }
            });
        }
    }

    getTopicName = (uid, topicId) => {
        // Topic name is not saved together with dialog info. We need to go get it from topicID
        let ref = firebase.database().ref(`users/${uid}/topics/${topicId}`);
        ref.once('value', snapshot => {
            if (snapshot.val() !== undefined && snapshot.val() !== null) {
                this.setState({ topicName: snapshot.val().name });
            }
        });
    }

    render() {
        var data = this.state.data;

        //console.log('Table data', data);
        const tableIcons = {
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
            FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
            DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
            NoteAddOutlined: forwardRef((props, ref) => <NoteAddOutlined {...props} ref={ref} />),
        };

        const handleClose = () => {
            this.setState({ isAddNew: false });
        };

        const handleDelete = () => {
            // Delete the last row of the table. Need to update the list and update again
            var data = [...this.state.data];
            data.pop();

            console.log('This is a data to save', data);
            updateFirebase(data);
            this.setState({ isAddNew: false });
        };




        const handleSave = () => {
            // Save an edit phrase. Need to update the list and update firebase again
            var cardData = this.state.cardData;
            var rowId = this.state.rowId;
            var data = [...this.state.data];
            if (rowId > -1) {
                // Add a new row to the table
                data[rowId] = cardData
            } else {
                // Edit a row in the table
                data.push(cardData);
            }


            console.log('THis is a data to save', cardData, data, rowId)
            if (isCardValid(cardData)) {
                updateFirebase(data);
                this.setState({ isAddNew: false });
            }

            else (
                // Data is invalid
                alert('Please filled in all required fields!')
            )


        };

        const updateFirebase = (data) => {
            // Convert table data into firebase data and upload. 

            const uid = this.state.uid;
            const dialogId = this.state.dialogId;

            let l1 = [];
            let l2 = [];
            let n = [];
            for (var i = 0; i < data.length; i++) {
                var rowData = data[i]
                l1.push(rowData.l1);
                l2.push(rowData.l2);
                n.push(rowData.n);
            }
            // Save to firebase
            console.log('SAVE To Firebase', l1, l2, n);
            firebase.database().ref(`users/${uid}/dialogs/${dialogId}`).update({ l1: l1, l2: l2, n: n });
        };




        const isCardValid = (cardData) => {
            var { l1, l2, n } = cardData;
            var isCardValid = (l1 !== undefined && l1 !== undefined && n !== undefined && l1 !== '' && l2 !== '' && n !== '');
            return isCardValid
        };

        const handleChange = (event) => {
            // Update when card field is editted
            var cardDataTemp = this.state.cardData;
            var cardData = { ...cardDataTemp, [event.target.name]: event.target.value }
            console.log('cardData', cardData)
            this.setState({
                cardData: cardData,
            });

        };


        const linkSpacer = ' > ';

        return (
            <div>
                <div style={{ marginBottom: 20, fontSize: 20 }}>
                    <Link to={{
                        pathname: '/topic',
                    }}>Home</Link>
                    {this.state.topicName == 'undefined' ? '' : linkSpacer}

                    <Link to={{
                        pathname: `/dialoglist/${this.state.topicId}`
                    }}> {this.state.topicName == 'undefined' ? '' : this.state.topicName} </Link>


                    {linkSpacer}  {this.state.dialogName}
                </div>


                <MaterialTable
                    icons={tableIcons}
                    localization={{ body: { emptyDataSourceMessage: 'There is no card yet, please select add new card to start' } }}
                    title={''}
                    columns={[
                        { title: 'Name', field: 'n', width: 50 },
                        { title: 'Language 1', field: 'l1', width: 200 },
                        { title: 'Language 2', field: 'l2', width: 200 },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: tableIcons['NoteAddOutlined'],
                            tooltip: 'Add new phase',
                            isFreeAction: true,
                            onClick: (event) => {
                                this.setState({ cardData: {}, isAddNew: true, rowId: -1 });
                            }
                        },
                        {
                            icon: tableIcons['Delete'],
                            tooltip: 'Delete a phase',
                            isFreeAction: true,
                            onClick: (event) => {
                                this.setState({ cardData: {}, isAddNew: true });
                                handleDelete();
                            }
                        },

                        {
                            icon: tableIcons['Edit'],
                            tooltip: 'Edit phrase',
                            onClick: (event, rowData) => {
                                var rowId = rowData.tableData['id'];
                                var cardData = this.state.data[rowId];
                                console.log('rowId', rowId, cardData);
                                console.log('questionData', cardData);
                                this.setState({ cardData: cardData, isAddNew: true, rowId: rowId });
                            }
                        },

                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        defaultExpanded: true,
                        pageSize: 20,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [20, 50, 200],
                        tableLayout: 'fixed',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },


                    }}
                />

                {/* Pop up add and edit dialog */}
                <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.state.cardData.id == undefined ? 'Add new card' : 'Edit card'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            id="standard-multiline-flexible"
                            label="Name"
                            name="n"
                            multiline
                            rowsMax="5"
                            fullWidth
                            value={this.state.cardData.n}
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            id="standard-multiline-flexible"
                            label="Language 1"
                            name="l1"
                            multiline
                            rowsMax="5"
                            fullWidth
                            value={this.state.cardData.l1}
                            onChange={handleChange}
                        />

                        <TextField
                            required
                            id="standard-multiline-flexible"
                            label="Language 2"
                            name="l2"
                            multiline
                            rowsMax="5"
                            fullWidth
                            value={this.state.cardData.l2}
                            onChange={handleChange}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button onClick={handleSave} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}
