import React, { Component } from 'react';
import firebase from 'firebase';
import MaterialTable from "material-table";
import _ from 'lodash';
import { Link } from 'react-router-dom';

// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';

// Flashcard Icons
import FolderOpen from '@material-ui/icons/FolderOpen';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertAddAlert } from 'material-ui/svg-icons';

export default class Dialoglist extends Component {
  constructor(props) {
    super(props);


    this.state = {
      data: [],
      itemData: {},
      isAddNew: false,
      uid: '',
      isExpand: false,
      topicId: '',
      topicName: '',
    }
  }



  componentDidMount() {
    // Get topicId from the url
    const topicId = this.props.match.params.id;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ uid: user.uid, topicId: topicId })
        this.getTopicData(user.uid, topicId);
        console.log('You are login as', user.uid, topicId);
      }
    });
  }

  componentDidUpdate() {
  }


  getTopicData = (uid, topicId) => {
    // Get dialogs data based on topicid
    console.log('Load list of dialog in a topic data');
    let ref = firebase.database().ref(`users/${uid}/topics/${topicId}`);
    ref.on('value', snapshot => {
      if (snapshot.val() !== undefined && snapshot.val() !== null) {
        var dataFirebase = snapshot.val();
        console.log('RETRIEVE data', snapshot.val());
        dataFirebase['id'] = topicId;
        var data = this.convertToTableData([dataFirebase]);
        console.log('TABLE data', dataFirebase);
        this.setState({ data: data, topicName: dataFirebase.name });
      }
    })
  }

  convertToTableData = (data) => {
    // Convert Firebase data to Tabledata format for display
    var newdata = [];
    data.forEach(topic => {
      console.log(topic);
      var dialogs = topic.dialogs;
      var topicId = topic.id;
      // Put id to the array
      if (dialogs !== undefined) {
        dialogs = Object.entries(dialogs).map(e =>
          Object.assign(e[1], { id: e[0] })
        );

        // Loop through cardsets to get data for the table
        dialogs.forEach(dialog => {
          var row = {
            id: dialog.id,
            name: dialog.name,
            desc: dialog.desc,
            topicId: topicId,
            level: dialog.level,
          };
          newdata.push(row);
        });
      }
    });

    return newdata;
  }


  render() {
    const data = this.state.data;
    console.log('Table data', data);


    const tableIcons = {
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
      PostAddOutlined: forwardRef((props, ref) => <PostAddOutlined {...props} ref={ref} />),
    };

    const handleClose = () => {
      this.setState({ isAddNew: false });
    };

    const handleDelete = (itemData) => {
      var result = window.confirm("Want to delete?");
      if (result) {
        console.log('DATA DELETE', itemData, this.state.data);
        var uid = this.state.uid;
        var dialogId = itemData.id;
        var topicId = itemData.topicId;

        console.log('cardsetId to be deleted', topicId, dialogId, itemData);
        firebase.database().ref(`users/${uid}/dialogs/${dialogId}`).remove();
        firebase.database().ref(`users/${uid}/topics/${topicId}/dialogs/${dialogId}`).remove();
      }
    };

    const handleSave = () => {
      // Save a topic
      var itemData = this.state.itemData;
      if (isItemValid(itemData)) {
        // Save this topic to the firebase
        // Based ont the itemData.id we know if it's existing one or new
        var uid = this.state.uid;
        var topicId = '';
        var dialogId = '';
        dialogId = itemData.id;
        topicId = itemData.topicId;
        if (dialogId == undefined) {
          // New Dialog
          topicId = this.state.topicId; // Get topic id from the url not the item as this is not an edit
          dialogId = firebase.database().ref(`users/${uid}/dialogs`).push().key;
          //console.log('cardsetItem', cardsetItem, cardsetId, folderId);
          var DialogItem = { name: itemData.name, topicId: topicId, level: itemData.level, desc: itemData.desc, updateAt: new Date().getTime() };
          var topicDialogItem = { name: itemData.name, level: itemData.level, desc: itemData.desc, updateAt: new Date().getTime() };
          firebase.database().ref(`users/${uid}/dialogs/${dialogId}`).set(DialogItem);
          firebase.database().ref(`users/${uid}/topics/${topicId}/dialogs/${dialogId}`).set(topicDialogItem);

        }
        else {
          // Edit Dialog
          var DialogItem = { name: itemData.name, topicId: topicId, level: itemData.level, desc: itemData.desc, updateAt: new Date().getTime() };
          var topicDialogItem = { name: itemData.name, level: itemData.level, desc: itemData.desc, updateAt: new Date().getTime() };
          firebase.database().ref(`users/${uid}/dialogs/${dialogId}`).update(DialogItem);
          firebase.database().ref(`users/${uid}/topics/${topicId}/dialogs/${dialogId}`).update(topicDialogItem);
        }

        // Turn off the edit dialog after save
        this.setState({ isAddNew: false });
      }
      else (
        // Question is invalid
        alert('Please filled in all required fields!')
      )
    };

    const isItemValid = (itemData) => {
      var { name } = itemData;
      var isItemValid = (name !== undefined);
      return isItemValid
    };

    const handleChange = (event) => {
      // Update when question field is editted
      var itemDataTemp = this.state.itemData;
      var itemData = { ...itemDataTemp, [event.target.name]: event.target.value }
      console.log('itemData', itemData)
      this.setState({
        itemData: itemData,
      });

    };

    const handleRowClick = ((event, rowData) => {
      // Update when question field is editted
      var dialogId = rowData.id;
      this.props.history.push({ pathname: `/phrase/${dialogId}` });
    });

    const linkSpacer = ' > ';

    return (
      <div>
        {/* Navigation between list of folders, list of cardsets and list of cards */}
        <div style={{ marginBottom: 20, fontSize: 20 }}>
          <Link to={{ pathname: '/topic' }}>Home</Link>
          {this.state.topicName !== '' ? `${linkSpacer}${this.state.topicName}` : ''}
        </div>

        <MaterialTable
          icons={tableIcons}
          localization={{ body: { emptyDataSourceMessage: 'There is no dialog in this topic, please select add new dialog to start' } }}
          title='List of dialogs'
          onRowClick={handleRowClick}
          data={data}
          columns={[
            { title: '', width: 50, render: rowData => <DescriptionOutlined /> },
            { title: 'Name', field: 'name', width: 300 },
            { title: 'Description', field: 'desc', width: 300 },
            { title: 'Level', field: 'level', width: 50 },
          ]}
          actions={[
            {
              icon: tableIcons['PostAddOutlined'],
              tooltip: 'Add new dialog',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ itemData: {}, isAddNew: true });
              }
            },

            {
              icon: tableIcons['Edit'],
              tooltip: 'Edit dialog',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                console.log('rowId', rowId, itemData);
                console.log('itemData', itemData);
                this.setState({ itemData: itemData, isAddNew: true });
              }
            },
            {
              icon: tableIcons['Delete'],
              tooltip: 'Delete an item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                this.setState({ itemData: itemData });
                handleDelete(itemData);
              }
            },
          ]}

          options={{
            //selection: true,
            actionsColumnIndex: -1,
            //defaultExpanded: this.state.isExpand,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100],
            tableLayout: 'fixed',
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />

        {/* Pop up add and edit dialog */}
        <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.state.itemData.id == undefined ? 'Add new dialog' : 'Edit dialog'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              id="standard-multiline-flexible"
              label="Name"
              name="name"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.name}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Description"
              name="desc"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.desc}
              onChange={handleChange}
            />
            <TextField
              required
              id="standard-multiline-flexible"
              label="Level"
              name="level"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.level}
              onChange={handleChange}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
