import React, { Component } from 'react';
//import firebase from 'firebase';

import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";

import MaterialTable from "material-table";
import _ from 'lodash';


// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';


// Flashcard Icons
import FolderOpen from '@material-ui/icons/FolderOpen';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertAddAlert } from 'material-ui/svg-icons';

const queryString = require('query-string');

export default class Flashcard extends Component {
  constructor(props) {
    super(props);


    this.state = {
      data: [],
      itemData: {},
      isAddNew: false,
      uid: '',
      isExpand: false,
      folderId: '',
      folderName: '',
      isFolderEdit: false
    }
  }



  componentDidMount() {
    //const folderId = this.props.match.params.id;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ uid: user.uid })
        this.getFlashcardData(user.uid);
        console.log('You are login as', user.uid);
      }
    });
  }

  componentDidUpdate() {
    // Check if folderId changes in the link or not
    if (this.state.folderId !== this.props.match.params.id) {
      this.getFlashcardData(this.state.uid, this.props.match.params.id);
      this.setState({ folderId: this.props.match.params.id })
    }
  }


  getFlashcardData = (uid) => {
    console.log('Load all folder data', `users/${uid}/folder`);
    let ref = firebase.database().ref(`users/${uid}/folder`);
    ref.on('value', snapshot => {
      console.log('DATA SNAPSHOT', snapshot.val());
      if (snapshot.val() !== undefined && snapshot.val() !== null) {
        const dataFirebase = Object.entries(snapshot.val()).map(e => Object.assign(e[1], { id: e[0] }));
        console.log('DATA RETRIEVED', dataFirebase);
        var data = this.convertToTableData(dataFirebase);
        console.log('TABLE data', data);
        this.setState({ data: data, isExpand: false });
      }
    });





  }

  convertToTableData = (data) => {
    // Convert Firebase data to Tabledata format for display
    var newdata = [];
    data.forEach(folder => {
      console.log(folder);
      if (folder.name == 'undefined') { // List of cardsets to display in the homepage
        var cardsets = folder.cardsets;
        var folderId = folder.id;
        // Put id to the array
        if (cardsets !== undefined) {
          cardsets = Object.entries(cardsets).map(e =>
            Object.assign(e[1], { id: e[0] })
          );

          // Loop through cardsets to get data for the table
          cardsets.forEach(cardset => {
            var row = {
              id: cardset.id,
              name: cardset.name,
              Ncard: cardset.Ncard,
              isFolder: false,
              folderId: folderId,
            };
            newdata.push(row);
          });
        }
      }
      else { // Just a folder
        var row = {
          id: folder.id,
          name: folder.name,
          Ncardset: folder.Ncardset,
          isFolder: true
        };
        newdata.push(row);
      }
    });

    return newdata;

  }


  render() {
    const data = this.state.data;
    console.log('Table data', data);



    const tableIcons = {
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
      CreateNewFolderOutlined: forwardRef((props, ref) => <CreateNewFolderOutlined {...props} ref={ref} />),
      PostAddOutlined: forwardRef((props, ref) => <PostAddOutlined {...props} ref={ref} />),
      NoteAddOutlined: forwardRef((props, ref) => <NoteAddOutlined {...props} ref={ref} />),
    };

    const handleClose = () => {
      this.setState({ isAddNew: false });
    };

    const handleDelete = (itemData) => {
      console.log('this.state.isFolderEdit', this.state.isFolderEdit);
      if (itemData.isFolder) { //Delete folder
        var folderId = itemData.id;
        var uid = this.state.uid;
        console.log('folderId to be deleted', folderId, uid, itemData);
        if (itemData.Ncardset == 0 || itemData.Ncardset == undefined) {
          firebase.database().ref(`users/${uid}/folder/${folderId}`).remove();
        }
        else {
          alert('You need to delete all cardsets first');
        }

      }
      else {//Delete cardset
        var result = window.confirm("Want to delete?");
        if (result) {
          console.log('DATA DELETE', itemData, this.state.data);
          var uid = this.state.uid;
          var cardsetId = itemData.id;
          var folderId = itemData.folderId;
          // Do not need to update folder Ncardset as it's not used
          console.log('cardsetId to be deleted', folderId, cardsetId, itemData);
          firebase.database().ref(`users/${uid}/cardsets/${cardsetId}`).remove();
          firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}`).remove();
        }
      }
    };

    const handleSave = () => {
      // Save an edit question
      var itemData = this.state.itemData;
      if (isItemValid(itemData)) {
        // Save this question back to the firebase
        // Based ont the itemData.id we know if it's exisitng one or new

        var uid = this.state.uid;
        var folderId = '';
        var cardsetId = '';
        if (this.state.isFolderEdit) { // Folder form
          folderId = itemData.id;
          if (folderId == undefined) {
            // Add new folder
            var folderItem = { name: itemData.name, Ncardset: 0 };
            folderId = firebase.database().ref(`users/${uid}/folder`).push().key;
            firebase.database().ref(`users/${uid}/folder/${folderId}`).update(folderItem);
          }
          else {
            // Edit folder 
            firebase.database().ref(`users/${uid}/folder/${folderId}/name`).set(itemData.name);
          }
        }
        else { // Cardset form
          cardsetId = itemData.id;
          folderId = itemData.folderId;
          if (cardsetId == undefined) {
            // New Cardset
            console.log('DATA TO CHECK IF THERE IS UNDEFINED FOLDER', this.state.data)
            var dataTemp = _.filter(this.state.data, item => !item.isFolder);
            if (dataTemp.length == 0) {
              //Create an undefined folder
              var folderItem = { name: 'undefined', Ncardset: 0 };
              folderId = firebase.database().ref(`users/${uid}/folder`).push().key;
              firebase.database().ref(`users/${uid}/folder/${folderId}`).update(folderItem);
            }
            else {
              folderId = dataTemp[0].folderId;
            }

            // Prepare data to update
            var cardsetItem = { name: itemData.name, folderId: folderId, updateAt: new Date().getTime(), };
            var folderCardsetItem = { name: itemData.name, updateAt: new Date().getTime(), Ncard: 0 };
            var Ncardset = this.state.data.length;

            // Update data to firebase
            cardsetId = firebase.database().ref(`users/${uid}/cardsets`).push().key;
            firebase.database().ref(`users/${uid}/cardsets/${cardsetId}`).update(cardsetItem);
            firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}`).update(folderCardsetItem);
            firebase.database().ref(`users/${uid}/folder/${folderId}/Ncardset`).set(Ncardset);
          }
          else {
            // Edit cardset name
            firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/name`).set(itemData.name);
            firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}/name`).set(itemData.name);
          }
        }


        this.setState({ isAddNew: false });
      }
      else (
        // Question is invalid
        alert('Please filled in all required fields!')
      )


    };

    const isItemValid = (itemData) => {
      var { name } = itemData;
      var isItemValid = (name !== undefined);
      return isItemValid
    };

    const handleChange = (event) => {
      // Update when question field is editted
      var itemDataTemp = this.state.itemData;
      var itemData = { ...itemDataTemp, [event.target.name]: event.target.value }
      console.log('itemData', itemData)
      this.setState({
        itemData: itemData,
      });

    };

    const handleRowClick = ((event, rowData) => {
      // Select row go to different link
      if (!rowData.isFolder) {
        //Go to cardset details
        var cardsetId = rowData.id;
        this.props.history.push({
          pathname: `/cardlist/${cardsetId}`
        }
        );
      }
      else {
        // Go to colletion details
        this.props.history.push({
          pathname: `/cardsetlist/${rowData.id}`
        });
      }
    });

    const linkSpacer = ' > ';

   

    return (
      <div>
        <MaterialTable
          icons={tableIcons}
          localization={{ body:{ emptyDataSourceMessage:'There is no folder or cardset yet, please select add new folder or cardset to start'} }}
          title=''
          onRowClick={handleRowClick}
          data={data}
          columns={[
            { title: '', field: 'isFolder', width: 50, defaultSort: 'desc', render: rowData => rowData.isFolder ? <FolderOpen /> : <DescriptionOutlined /> },
            { title: 'Name', field: 'name', width: 500 },
            {
              title: 'Number of cardsets', field: 'Ncardset', width: 150
            },
            {
              title: 'Number of cards', field: 'Ncard', width: 150
            },
          ]}
          actions={[
            {
              icon: tableIcons['CreateNewFolderOutlined'],
              tooltip: 'Add a new folder',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ itemData: {}, isAddNew: true, isFolderEdit: true });
              }
            },
            {
              icon: tableIcons['PostAddOutlined'],
              tooltip: 'Add a new cardset',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ itemData: {}, isAddNew: true, isFolderEdit: false });
              }
            },


            {
              icon: tableIcons['Edit'],
              tooltip: 'Edit item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                console.log('rowId', rowId, itemData);
                console.log('itemData', itemData);
                this.setState({ itemData: itemData, isAddNew: true, isFolderEdit: itemData.isFolder });
              }
            },
            {
              icon: tableIcons['Delete'],
              tooltip: 'Delete an item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                this.setState({ itemData: itemData, isFolderEdit: itemData.isFolder });
                handleDelete(itemData);
              }
            },
          ]}
          //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          options={{
            //selection: true,
            actionsColumnIndex: -1,
            defaultExpanded: this.state.isExpand,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100, 200],
            tableLayout: 'fixed',
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />

        {/* Pop up add and edit dialog */}
        <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.state.isFolderEdit ? this.state.itemData.id == undefined ? 'Add new folder' : 'Edit Folder'
            : this.state.itemData.id == undefined ? 'Add new cardset' : 'Edit Cardset'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              size='medium'
              id="standard-multiline-flexible"
              label="Name"
              name="name"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.name}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
        </Button>
            <Button onClick={handleSave} color="primary">
              Save
        </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
