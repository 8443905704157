import React, { Component } from 'react';
import firebase from 'firebase';
import MaterialTable from "material-table";
import _ from 'lodash';
import {Link} from 'react-router-dom';

// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';

// Flashcard Icons
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertAddAlert } from 'material-ui/svg-icons';



export default class Cardlist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            cardData: {},
            isAddNew: false,
            uid: '',
            cardsetName: '',
            cardsetId: '',
            folderName: '',
            folderId: '',
        }
    }



    componentDidMount() {

        // Get cardsetId from url
        var cardsetId = this.props.match.params.id;
      
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.getCardsData(user.uid, cardsetId);
            }
        });
    }

    getCardsData = (uid, cardsetId) => {
        
const  isImageCard = (text) => {
    var isImageUrl = (text.match(/(http(s)?:\/\/.)?(www.)?[-a-zA-Z0-9@:%.+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&//=]*)/g) !== null);
    return isImageUrl
}

        if (uid !== '') {
            let cardsData = [];
            let ref = firebase.database().ref(`users/${uid}/cardsets/${cardsetId}`);
            ref.on('value', snapshot => {
                if (snapshot.val() !== undefined && snapshot.val() !== null) {
                    console.log('snapshot.val().cards', snapshot.val().cards, cardsData);
                    if (snapshot.val().cards !== undefined && snapshot.val().cards !== null) {
                        cardsData = Object.entries(snapshot.val().cards).map(e => Object.assign(e[1], { id: e[0] }))
                    }
                    else {
                        cardsData = [];
                    }



                    console.log('DATA RETRIEVED', snapshot.val(), cardsData);
                    var cardsetName = snapshot.val().name;
                    var folderId = snapshot.val().folderId;
                    this.getFolderName(uid, folderId);
                    this.setState({ data: cardsData, cardsetId: cardsetId, cardsetName: cardsetName, folderId: folderId, uid: uid });
                }
                else {
                    this.setState({ data: [] });
                }
            });
        }
    }

    getFolderName = (uid, folderId) => {
        let ref = firebase.database().ref(`users/${uid}/folder/${folderId}`);
        ref.once('value', snapshot => {
            if (snapshot.val() !== undefined && snapshot.val() !== null) {
                this.setState({ folderName: snapshot.val().name });
            }
        });
    }

   isImageCard = (text) => {
        var isImageUrl = (text.match(/(http(s)?:\/\/.)?(www.)?[-a-zA-Z0-9@:%.+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&//=]*)/g) !== null);
        return isImageUrl
    }



    render() {
        var data = this.state.data;

        //console.log('Table data', data);
        const tableIcons = {
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
            FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
            DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
            NoteAddOutlined: forwardRef((props, ref) => <NoteAddOutlined {...props} ref={ref} />),
        };

        const handleClose = () => {
            this.setState({ isAddNew: false });
        };

        const handleDelete = (cardData) => {
            var result = window.confirm("Want to delete?");
            if (result) {
                var folderId = this.state.folderId;
                var cardsetId = this.state.cardsetId;
                var cardId = cardData.id;
                var uid = this.state.uid;
                var Ncard = this.state.data.length - 1;
                console.log('questionId to be deleted', cardId, cardData, uid, folderId);
                firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/cards/${cardId}`).remove();
                firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}/Ncard`).set(Ncard); //Logic to delete the item
            }

            
        };

        const handleFlip = () => {
            var cardsetId = this.state.cardsetId;
            var uid = this.state.uid;
            var data = this.state.data;
            console.log('cardsetId to be flip', cardsetId, data, uid);
            data.forEach((item, index) => {
                var {front, frontimg, back, backimg} = item;
                var cardId = item.id;
                var newitem = {'front': back?back:{}, 'frontimg':backimg?backimg:{}, 'back': front?front:{}, 'backimg':frontimg?frontimg:{}}
                console.log('NEWITEM', newitem);
                firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/cards/${cardId}`).set(newitem);
            })
          
            
        };

        const handleSave = () => {
            // Save an edit card
            var cardData = this.state.cardData;

            if (isCardValid(cardData)) {
                // Save this card back to the firebase
                var uid = this.state.uid;
                var cardsetId = this.state.cardsetId;
                var cardId = cardData.id;
                // Do not save tableData and id
                cardData.tableData = {};
                cardData.id = {};
                console.log('SAVE To Firebase', uid, cardsetId, cardId, cardData);
                if (cardId == undefined) {
                    // New question
                    var Ncard = this.state.data.length + 1;
                    var folderId = this.state.folderId;
                    cardId = firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/cards/`).push().key;
                    firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/cards/${cardId}`).update(cardData);
                    firebase.database().ref(`users/${uid}/folder/${folderId}/cardsets/${cardsetId}/Ncard`).set(Ncard);

                }
                else {
                    firebase.database().ref(`users/${uid}/cardsets/${cardsetId}/cards/${cardId}`).update(cardData);
                }



                this.setState({ isAddNew: false });
            }
            else (
                // Question is invalid
                alert('Please filled in all required fields!')
            )


        };

        const isCardValid = (cardData) => {
            var { front, back } = cardData;
            var isCardValid = (front !== undefined && back !== undefined && front !== '' && back !== '');
            return isCardValid
        };

        const isImageCard = (text) => {
            var isImageUrl = (text.match(/^https:\/\//i) !== null);
            console.log('Check URL', isImageUrl, text)
            return isImageUrl

        };
    

      
        const handleChange = (event) => {
            // Update when card field is editted
            var cardDataTemp = this.state.cardData;
            var cardData = { ...cardDataTemp, [event.target.name]: event.target.value }
            console.log('cardData', cardData)
            this.setState({
                cardData: cardData,
            });

        };


        const linkSpacer = ' > ';

        return (
            <div>
                <div style={{ marginBottom: 20, fontSize: 20 }}>
                    <Link to={{
                        pathname: '/flashcard',
                    }}>Home</Link>
                    {this.state.folderName == 'undefined' ? '' : linkSpacer}

                    <Link to={{
                        pathname: `/cardsetlist/${this.state.folderId}`
                    }}> {this.state.folderName == 'undefined' ? '' : this.state.folderName} </Link>


                    {linkSpacer}  {this.state.cardsetName}
                </div>


                <MaterialTable
                    icons={tableIcons}
                    localization={{ body:{ emptyDataSourceMessage:'There is no card yet, please select add new card to start'} }}
                    title={''}
                    columns={[
                        // { title: 'frontimg', field: 'frontimg',hidden: !this.state.isFrontImg, width: "100px", render: item => item.front && <img src={item.front} alt="" border="3" height="100" width="100" />},
                        { title: 'Front', field: 'front', render: item => isImageCard(item.front) ? <img src={item.front} title={item.front_hint} alt="" border="3" height="100" /> : <div>{item.front}</div> },
                        { title: 'Back', field: 'back' , render: item => isImageCard(item.back) ? <img src={item.back} title={item.back_hint} border="3" height="100" /> : <div>{item.back}</div> },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: tableIcons['NoteAddOutlined'],
                            tooltip: 'Add new card',
                            isFreeAction: true,
                            onClick: (event) => {
                                this.setState({ cardData: {}, isAddNew: true });
                            }
                        },
                        {
                            icon: tableIcons['SortArrow'],
                            tooltip: 'Flip front and back',
                            isFreeAction: true,
                            onClick: (event) => {
                                handleFlip();
                            }
                        },
                        {
                            icon: tableIcons['Edit'],
                            tooltip: 'Edit card',
                            onClick: (event, rowData) => {
                                var rowId = rowData.tableData['id'];
                                var cardData = this.state.data[rowId];
                                console.log('rowId', rowId, cardData);
                                console.log('questionData', cardData);
                                this.setState({ cardData: cardData, isAddNew: true });
                            }
                        },
                        {
                            icon: tableIcons['Delete'],
                            tooltip: 'Delete a card',
                            onClick: (event, rowData) => {
                                var rowId = rowData.tableData['id'];
                                var cardData = this.state.data[rowId];
                                handleDelete(cardData);
                            }

                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        defaultExpanded: true,
                        pageSize: 100,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [50, 100, 200, 500],
                        tableLayout: 'fixed',
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },


                    }}
                />

                {/* Pop up add and edit dialog */}
                <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.state.cardData.id == undefined ? 'Add new card' : 'Edit card'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            required
                            id="standard-multiline-flexible"
                            label="Front"
                            name="front"
                            multiline
                            rowsMax="5"
                            fullWidth
                            value={this.state.cardData.front}
                            onChange={handleChange}
                        />
                        <TextField
                            required
                            id="standard-multiline-flexible"
                            label="Back"
                            name="back"
                            multiline
                            rowsMax="5"
                            fullWidth
                            value={this.state.cardData.back}
                            onChange={handleChange}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button onClick={handleSave} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}
