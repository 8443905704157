import React, { Component } from 'react';
//import firebase from 'firebase';

import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/database";

import MaterialTable from "material-table";
import _ from 'lodash';


// Quiz Icons
import { forwardRef } from 'react';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Add from '@material-ui/icons/Add';


// Flashcard Icons
import FolderOpen from '@material-ui/icons/FolderOpen';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import CreateNewFolderOutlined from '@material-ui/icons/CreateNewFolderOutlined';
import PostAddOutlined from '@material-ui/icons/PostAddOutlined';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';

// For add form
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AlertAddAlert } from 'material-ui/svg-icons';

const queryString = require('query-string');

export default class Topic extends Component {
  constructor(props) {
    super(props);


    this.state = {
      data: [],
      itemData: {},
      isAddNew: false,
      uid: '',
      isExpand: false,
      topicId: '',
      topicName: '',
      isTopicEdit: false
    }
  }



  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ uid: user.uid })
        this.getTopicData(user.uid);
        console.log('You are login as', user.uid);
      }
    });
  }

  componentDidUpdate() {
    // Check if topicId changes in the link or not
    if (this.state.topicId !== this.props.match.params.id) {
      this.getTopicData(this.state.uid, this.props.match.params.id);
      this.setState({ topicId: this.props.match.params.id })
    }
  }


  getTopicData = (uid) => {
    console.log('Load all folder data', `users/${uid}/topics`);
    let ref = firebase.database().ref(`users/${uid}/topics`);
    ref.on('value', snapshot => {
      console.log('DATA SNAPSHOT', snapshot.val());
      if (snapshot.val() !== undefined && snapshot.val() !== null) {
        const dataFirebase = Object.entries(snapshot.val()).map(e => Object.assign(e[1], { id: e[0] }));
        console.log('DATA RETRIEVED', dataFirebase);
        var data = this.convertToTableData(dataFirebase);
        console.log('TABLE data', data);
        this.setState({ data: data, isExpand: false });
      }
    });
  }

  convertToTableData = (data) => {
    // Convert Firebase data to Tabledata format for display
    var newdata = [];
    data.forEach(topic => {
      console.log(topic);
      var row = {
        id: topic.id,
        name: topic.name,
        desc: topic.desc,
        dialogs: topic.dialogs,
        img: topic.img,
        level: topic.level,
      };
      newdata.push(row);
    });
    return newdata;
  }

  render() {
    const data = this.state.data;
    console.log('Table data', data);


    const tableIcons = {
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
      DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
      Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
      Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
      FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
      LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
      NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
      PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
      ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
      SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
      ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
      ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
      Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      FolderOpen: forwardRef((props, ref) => <Add {...props} ref={ref} />),
      DescriptionOutlined: forwardRef((props, ref) => <DescriptionOutlined {...props} ref={ref} />),
      CreateNewFolderOutlined: forwardRef((props, ref) => <CreateNewFolderOutlined {...props} ref={ref} />),
      PostAddOutlined: forwardRef((props, ref) => <PostAddOutlined {...props} ref={ref} />),
      NoteAddOutlined: forwardRef((props, ref) => <NoteAddOutlined {...props} ref={ref} />),
    };

    const handleClose = () => {
      this.setState({ isAddNew: false });
    };

    const handleDelete = (itemData) => {
      // Delete a topic
      var topicId = itemData.id;
      var uid = this.state.uid;
      console.log('topicId to be deleted', topicId, uid, itemData);
      if (itemData.dialogs == undefined) {
        firebase.database().ref(`users/${uid}/topics/${topicId}`).remove();
      }
      else {
        alert('You need to delete all dialogs first');
      }
    };

    const handleSave = () => {
      // Save a topic
      var itemData = this.state.itemData;
      if (isItemValid(itemData)) {
        // Save topic back to the firebase
        // Based on tthe itemData.id we know if it's exisitng one or new

        var uid = this.state.uid;
        var topicId = '';
        topicId = itemData.id;
        var topicItem = { name: itemData.name, img: itemData.img, level: itemData.level, desc: itemData.desc };
        if (topicId == undefined) {
          // Add new topic
          topicId = firebase.database().ref(`users/${uid}/topics`).push().key;
          firebase.database().ref(`users/${uid}/topics/${topicId}`).set(topicItem);
        }
        else {
          // Edit topic 
          firebase.database().ref(`users/${uid}/topics/${topicId}`).update(topicItem);
        }
        this.setState({ isAddNew: false });
      }
      else (
        // Question is invalid
        alert('Please filled in all required fields!')
      )


    };

    const isItemValid = (itemData) => {
      var { name } = itemData;
      var isItemValid = (name !== undefined);
      return isItemValid
    };

    const handleChange = (event) => {
      // Update when topic field is editted
      var itemDataTemp = this.state.itemData;
      var itemData = { ...itemDataTemp, [event.target.name]: event.target.value }
      console.log('itemData', itemData)
      this.setState({
        itemData: itemData,
      });

    };

    const handleRowClick = ((event, rowData) => {
      // Select row go to different link
      this.props.history.push({
        pathname: `/dialoglist/${rowData.id}`
      });
    });

    const linkSpacer = ' > ';

    return (
      <div>
        <MaterialTable
          icons={tableIcons}
          localization={{ body: { emptyDataSourceMessage: 'There is no topic yet, please select add new topic to start' } }}
          title=''
          onRowClick={handleRowClick}
          data={data}
          columns={[
            { title: '', width: 50, defaultSort: 'desc', render: rowData => <DescriptionOutlined /> },
            { title: 'Name', field: 'name', width: 100 },
            {
              title: 'Description', field: 'desc', width: 150
            },
            {
              title: 'Level', field: 'level', width: 10
            },
            {
              title: 'img', field: 'img', width: 200
            },
          ]}
          actions={[
            {
              icon: tableIcons['PostAddOutlined'],
              tooltip: 'Add a new topic',
              isFreeAction: true,
              onClick: (event) => {
                this.setState({ itemData: {}, isAddNew: true });
              }
            },


            {
              icon: tableIcons['Edit'],
              tooltip: 'Edit item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                console.log('rowId', rowId, itemData);
                console.log('itemData', itemData);
                this.setState({ itemData: itemData, isAddNew: true });
              }
            },
            {
              icon: tableIcons['Delete'],
              tooltip: 'Delete an item',
              onClick: (event, rowData) => {
                var rowId = rowData.tableData['id'];
                var itemData = this.state.data[rowId];
                this.setState({ itemData: itemData });
                handleDelete(itemData);
              }
            },
          ]}
          //parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          options={{
            //selection: true,
            actionsColumnIndex: -1,
            defaultExpanded: this.state.isExpand,
            pageSize: 20,
            pageSizeOptions: [20, 50, 100, 200],
            tableLayout: 'fixed',
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: '#01579b',
              color: '#FFF'
            }
          }}
        />

        {/* Pop up add and edit dialog */}
        <Dialog open={this.state.isAddNew} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{this.state.itemData.id == undefined ? 'Add new topic' : 'Edit topic'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              size='medium'
              id="standard-multiline-flexible"
              label="Name"
              name="name"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.name}
              onChange={handleChange}
            />
            <TextField
              required
              size='medium'
              id="standard-multiline-flexible"
              label="Description"
              name="desc"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.desc}
              onChange={handleChange}
            />
            <TextField
              required
              size='medium'
              id="standard-multiline-flexible"
              label="Level"
              name="level"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.level}
              onChange={handleChange}
            />
            <TextField
              required
              size='medium'
              id="standard-multiline-flexible"
              label="Avatar"
              name="img"
              multiline
              rowsMax="5"
              fullWidth
              value={this.state.itemData.img}
              onChange={handleChange}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}
